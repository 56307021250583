import { put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import Config from 'Config';
import { I18n } from 'Locales';
import { CheckIn } from 'Repositories';
import * as Selectors from 'Selectors';
import { isUpdateRequired } from 'Helpers';
import { NavigationService } from 'Services';
import { COUNTRIES_ADDRESS } from 'Constants';
import { Types as FlowTypes } from 'Reducers/flow';
import { flowId as flowIdSelector } from 'Selectors';
import { Types as GrowlTypes } from 'Reducers/growl';
import { user as userSelector } from 'Reducers/user';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { Types as CampaignTypes } from 'Reducers/campaign';
import { transient as transientSelector, Types as TransientTypes } from 'Reducers/transient';
import { Types as PostCodesTypes, selectedPostcodes as selectedPostcodesSelector } from 'Reducers/postCodes';

export const checkIn = function* () {
  const selectedPostcodes = yield select(selectedPostcodesSelector);
  const user = yield select(userSelector);
  const flowId = yield select(flowIdSelector);

  if (selectedPostcodes.length > 0 || !COUNTRIES_ADDRESS.AUTOCOMPLETE.includes(user.country)) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: CheckInTypes.GET },
        fail: { type: Api.API_ERROR }
      },
      promise: CheckIn.checkIn(flowId, selectedPostcodes.join(', '))
    });
    yield put({
      type: PostCodesTypes.UPDATE_PROP,
      key: 'selectedPostcodes',
      value: []
    });
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        processing: true,
        new_published_flow: false
      }
    });
  }
};

export const checkOut = function* ({ id }) {
  const user = yield select(userSelector);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CheckInTypes.CHECK_OUT_SUCCESS, userId: user.id, checkInId: id },
      fail: { type: CheckInTypes.CHECK_OUT_ERROR }
    },
    promise: CheckIn.checkOut(id)
  });
};

export const checkOutError = function* ({ error }) {
  if (error?.response?.status === 404) {
    yield put({ type: CampaignTypes.GET });
    yield put({ type: CheckInTypes.GET });
    yield put({
      type: TransientTypes.UPDATE_PROP,
      key: 'processing',
      value: false
    });
  } else if (error?.response?.status !== 404) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.checkOutFail.title'),
      kind: 'error'
    });
  }
};

export const verifyCheckIn = function* ({ flowId }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CheckInTypes.VERIFY_CHECK_IN_SUCCESS, flowId },
      fail: { type: Api.API_ERROR }
    },
    promise: CheckIn.get()
  });
};

export const verifyCheckInSuccess = function* ({ payload, flowId }) {
  const transient = yield select(transientSelector);
  const currentCheckIn = yield select(Selectors.checkInObject);

  const checkInIds = payload?.map(item => item.id);

  if (checkInIds.includes(currentCheckIn.id)) {
    yield put({ type: FlowTypes.INIT_FLOW_METADATA, id: flowId });

    if (isUpdateRequired(transient.latestAppVersion, Config.APP_VERSION)) {
      yield put({
        type: TransientTypes.UPDATE_PROP,
        key: 'version_update_required',
        value: true
      });

      NavigationService.navigate({
        name: 'VersionUpdate'
      });
    } else {
      yield put({
        type: TransientTypes.UPDATE_PROPS,
        props: {
          loadingFlow: false
        }
      });
      NavigationService.navigate({ name: 'Flow', flowId });
    }
  } else {
    yield put({ type: CheckInTypes.CHECK_OUT, id: currentCheckIn.id });
  }
};

export const get = function* () {
  const user = yield select(userSelector);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CheckInTypes.SET, userId: user.id },
      fail: { type: Api.API_ERROR }
    },
    promise: CheckIn.get()
  });
};

export const set = function* ({ payload: checkIns, userId }) {
  const user = yield select(userSelector);

  for (const checkIn of checkIns) {
    if (checkIn.user.id === userId) {
      yield put({
        type: FlowTypes.GET,
        id: checkIn.published_flow.id,
        checkInId: checkIn.id
      });

      if (COUNTRIES_ADDRESS.AUTOCOMPLETE.includes(user.country)) {
        yield put({
          type: Api.API_CALL,
          actions: {
            success: {
              type: CheckInTypes.SET_ADDRESS,
              userId,
              checkInId: checkIn.id
            },
            fail: { type: Api.API_ERROR }
          },
          promise: CheckIn.getAddress(checkIn.selected_postcodes, checkIn.country.toLowerCase())
        });
      }
    }
  }
};
