import { all, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { checkSearchParams } from 'Helpers';

// TYPES
import { Types as FlowTypes } from 'Reducers/flow';
import { Types as UserTypes } from 'Reducers/user';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as ResultTypes } from 'Reducers/result';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { Types as CampaignTypes } from 'Reducers/campaign';
import { Types as PostCodesTypes } from 'Reducers/postCodes';
import { Types as ApplicationTypes, mode as modeSelector } from 'Reducers/application';

// SAGAS
import {
  apiError,
  activateAccount,
  activateAccountFail,
  activateAccountSuccess,
  checkOnline,
  backendLoginError,
  backendLoginSuccessful,
  brokenOrUnauthorizedAccess,
  checkDonorLoginCompleted,
  clearSession,
  closeSplashScreen,
  donorLogin,
  donorLoginCompleted,
  donorLoginError,
  donorLoginSuccessful,
  initFrameworkSeven,
  login,
  logout,
  navigateTo,
  onOrientationChange,
  persisted,
  postConfirmationToken,
  postConfirmationTokenFail,
  postConfirmationTokenSuccess,
  securityLogout,
  sessionExpiredLogout,
  userIsOnline
} from 'Sagas/application';

import {
  get as getCampaigns,
  getError as getCampaignsError,
  setCampaignsAndDefault,
  setToggledCampaigns
} from 'Sagas/campaign';

import {
  checkIn,
  checkOut,
  checkOutError,
  verifyCheckIn,
  verifyCheckInSuccess,
  get as getCheckIns,
  set as setCheckIns
} from 'Sagas/checkIn';

import {
  checkOfflineFlows,
  forceUpdatePageComponents,
  get as getFlow,
  getDonorFlow,
  getError as getFlowError,
  getPublishedFlows,
  getPublishedFlowsSuccess,
  initFlowMetadata,
  initSubmitFlow,
  navigateToFirstInvalidatedPage,
  nextFlowPage,
  previousFlowPage,
  runComponentLogicRules,
  runPageLogicRules,
  runPageValidations,
  saveFlowForOffline,
  setDonorFlow,
  submitFlow,
  submitFlowError,
  submitFlowSuccess,
  submitOfflineFlow,
  submitOfflineFlowSuccess,
  summaryFlowPage,
  validateOnlineBankAccount,
  validateOnlineBankAccountSuccess,
  validateOnlineBankAccountError
} from 'Sagas/flow';

import { alert } from 'Sagas/growl';

import {
  get as getResults,
  getResultsSuccess,
  getError as getResultsError,
  performTestResults,
  performTestResultsFail,
  performTestResultsSuccess,
  resendLinkToDonor,
  resendLinkToDonorFail,
  resendLinkToDonorSuccess,
  uploadResultPicture,
  uploadResultPictureFail,
  uploadResultPictureSuccess,
  uploadOfflineResultPictureSuccess
} from 'Sagas/result';

import {
  setCampaign as setCampaignOnUser,
  bankAccountCheckFR,
  bankAccountCheckUK,
  deleteAttachment,
  deleteAttachmentSuccess,
  getAttachments,
  getAttachmentsSuccess,
  getCountries,
  getCountriesSuccess,
  getOnboardingProfiles,
  getOnboardingProfilesSuccess,
  getPostCodes,
  getRoles,
  getRolesSuccess,
  getUKAddress,
  getUKAddressSuccess,
  getUKAddressFail,
  getUser,
  getUserSuccess,
  readAttachment,
  requestChangeUserPassword,
  requestChangeUserPasswordSuccess,
  resetPassword,
  resetPasswordFail,
  resetPasswordSuccess,
  sendFeedback,
  sendFeedbackFail,
  sendFeedbackSuccess,
  setCampaignSuccess,
  updateUser,
  updateUserAvatar,
  updateUserAvatarFail,
  updateUserAvatarSuccess,
  updateUserFail,
  updateUserSuccess,
  uploadAttachments,
  uploadAttachmentsSuccess,
  uploadAttachmentsFail,
  validateOnlineBankAccountUser,
  validateOnlineBankAccountUserSuccess
} from 'Sagas/user';

export default function* root() {
  const mode = yield select(modeSelector);

  const donorEffects = [
    takeLatest(ApplicationTypes.DONOR_LOGIN, donorLogin),
    takeLatest(ApplicationTypes.DONOR_LOGIN_ERROR, donorLoginError),
    takeLatest(ApplicationTypes.DONOR_LOGIN_COMPLETED, donorLoginCompleted),
    takeLatest(ApplicationTypes.DONOR_LOGIN_SUCCESSFUL, donorLoginSuccessful),

    takeLatest(FlowTypes.GET_DONOR_FLOW, getDonorFlow),
    takeLatest(FlowTypes.SET_DONOR_FLOW, setDonorFlow),

    takeLatest(FlowTypes.SET_DONOR_FLOW, checkDonorLoginCompleted),

    takeLatest(PostCodesTypes.SET_DONOR_ADDRESSES, checkDonorLoginCompleted)
  ];

  const mainEffects = [
    takeEvery('API_ERROR', apiError),
    takeLatest(ApplicationTypes.ACTIVATE_ACCOUNT, activateAccount),
    takeLatest(ApplicationTypes.ACTIVATE_ACCOUNT_FAIL, activateAccountFail),
    takeLatest(ApplicationTypes.ACTIVATE_ACCOUNT_SUCCESS, activateAccountSuccess),
    takeLatest(ApplicationTypes.CHECK_ONLINE, checkOnline),
    takeLatest(ApplicationTypes.BACKEND_LOGIN_ERROR, backendLoginError),
    takeLatest(ApplicationTypes.BACKEND_LOGIN_SUCCESSFUL, backendLoginSuccessful),
    takeLatest(ApplicationTypes.BROKEN_OR_UNAUTHORIZED_ACCESS, brokenOrUnauthorizedAccess),
    takeLatest(ApplicationTypes.CLEAR_SESSION, clearSession),
    takeLatest(ApplicationTypes.CLOSE_SPLASH_SCREEN, closeSplashScreen),
    takeLatest(ApplicationTypes.INIT_FRAMEWORK_SEVEN, initFrameworkSeven),
    takeLatest(ApplicationTypes.LOGIN, login),
    takeLatest(ApplicationTypes.LOGOUT, logout),
    takeLatest(ApplicationTypes.NAVIGATE_TO, navigateTo),
    takeLatest(ApplicationTypes.ON_ORIENTATION_CHANGE, onOrientationChange),
    takeLatest(ApplicationTypes.POST_CONFIRMATION_TOKEN, postConfirmationToken),
    takeLatest(ApplicationTypes.POST_CONFIRMATION_TOKEN_FAIL, postConfirmationTokenFail),
    takeLatest(ApplicationTypes.POST_CONFIRMATION_TOKEN_SUCCESS, postConfirmationTokenSuccess),
    takeLatest(ApplicationTypes.PERSISTED, persisted),
    takeLatest(ApplicationTypes.SECURITY_LOGOUT, securityLogout),
    takeLatest(ApplicationTypes.SESSION_EXPIRED_LOGOUT, sessionExpiredLogout),
    takeLatest(ApplicationTypes.USER_IS_ONLINE, userIsOnline),

    takeLatest(CheckInTypes.CHECK_IN, checkIn),
    takeLatest(CheckInTypes.CHECK_OUT, checkOut),
    takeLatest(CheckInTypes.CHECK_OUT_ERROR, checkOutError),
    takeLatest(CheckInTypes.GET, getCheckIns),
    takeLatest(CheckInTypes.VERIFY_CHECK_IN, verifyCheckIn),
    takeLatest(CheckInTypes.VERIFY_CHECK_IN_SUCCESS, verifyCheckInSuccess),
    takeLatest(CheckInTypes.SET, setCheckIns),

    takeLatest(CampaignTypes.GET, getCampaigns),
    takeLatest(CampaignTypes.GET_ERROR, getCampaignsError),
    takeLatest(CampaignTypes.SET_CAMPAIGNS_AND_DEFAULT, setCampaignsAndDefault),
    takeLatest(CampaignTypes.SET_TOGGLED_CAMPAIGNS, setToggledCampaigns),

    takeLatest(FlowTypes.CHECK_OFFLINE_FLOWS, checkOfflineFlows),
    takeLatest(FlowTypes.FORCE_UPDATE_PAGE_COMPONENTS, forceUpdatePageComponents),
    takeLatest(FlowTypes.GET, getFlow),
    takeLatest(FlowTypes.GET_PUBLISHED_FLOWS, getPublishedFlows),
    takeLatest(FlowTypes.GET_PUBLISHED_FLOWS_SUCCESS, getPublishedFlowsSuccess),
    takeLatest(FlowTypes.GET_ERROR, getFlowError),
    takeLatest(FlowTypes.INIT_FLOW_METADATA, initFlowMetadata),
    takeLatest(FlowTypes.INIT_SUBMIT_FLOW, initSubmitFlow),
    takeLatest(FlowTypes.INVALIDATE_COMPONENT, runPageValidations),
    takeLatest(FlowTypes.NAVIGATE_TO_FIRST_INVALIDATED_PAGE, navigateToFirstInvalidatedPage),
    takeLatest(FlowTypes.NEXT_FLOW_PAGE, nextFlowPage),
    takeLatest(FlowTypes.PREVIOUS_FLOW_PAGE, previousFlowPage),
    takeLatest(FlowTypes.RUN_PAGE_LOGIC_RULES, runPageLogicRules),
    takeLatest(FlowTypes.RUN_PAGE_VALIDATIONS, runPageValidations),
    takeLatest(FlowTypes.SAVE_FLOW_FOR_OFFLINE, saveFlowForOffline),
    takeLatest(FlowTypes.SUBMIT_FLOW, submitFlow),
    takeLatest(FlowTypes.SUBMIT_FLOW_ERROR, submitFlowError),
    takeLatest(FlowTypes.SUBMIT_FLOW_SUCCESS, submitFlowSuccess),
    takeLatest(FlowTypes.SUBMIT_OFFLINE_FLOW, submitOfflineFlow),
    takeLatest(FlowTypes.SUBMIT_OFFLINE_FLOW_SUCCESS, submitOfflineFlowSuccess),
    takeLatest(FlowTypes.SUMMARY_FLOW_PAGE, summaryFlowPage),
    takeEvery(FlowTypes.UPDATE_CURRENT_FLOW_DATA, runComponentLogicRules),
    takeLatest(FlowTypes.VALIDATE_COMPONENT, runPageValidations),
    takeLatest(FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT, validateOnlineBankAccount),
    takeLatest(FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_SUCCESS, validateOnlineBankAccountSuccess),
    takeLatest(FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_ERROR, validateOnlineBankAccountError),

    takeLatest(GrowlTypes.ALERT, alert),

    takeLatest(ResultTypes.GET, getResults),
    takeLatest(ResultTypes.GET_RESULTS_SUCCESS, getResultsSuccess),
    takeLatest(ResultTypes.GET_ERROR, getResultsError),
    takeLatest(ResultTypes.PERFORM_TEST_RESULTS, performTestResults),
    takeLatest(ResultTypes.PERFORM_TEST_RESULTS_FAIL, performTestResultsFail),
    takeLatest(ResultTypes.PERFORM_TEST_RESULTS_SUCCESS, performTestResultsSuccess),
    takeLatest(ResultTypes.RESEND_LINK_TO_DONOR, resendLinkToDonor),
    takeLatest(ResultTypes.RESEND_LINK_TO_DONOR_FAIL, resendLinkToDonorFail),
    takeLatest(ResultTypes.RESEND_LINK_TO_DONOR_SUCCESS, resendLinkToDonorSuccess),
    takeLatest(ResultTypes.UPLOAD_RESULT_PICTURE, uploadResultPicture),
    takeLatest(ResultTypes.UPLOAD_RESULT_PICTURE_FAIL, uploadResultPictureFail),
    takeLatest(ResultTypes.UPLOAD_RESULT_PICTURE_SUCCESS, uploadResultPictureSuccess),
    takeLatest(ResultTypes.UPLOAD_OFFLINE_RESULT_PICTURE_SUCCESS, uploadOfflineResultPictureSuccess),

    takeLatest(UserTypes.BANK_ACCOUNT_CHECK_FR, bankAccountCheckFR),
    takeLatest(UserTypes.BANK_ACCOUNT_CHECK_UK, bankAccountCheckUK),
    takeLatest(UserTypes.DELETE_ATTACHMENT_SUCCESS, deleteAttachmentSuccess),
    takeLatest(UserTypes.DELETE_ATTACHMENT, deleteAttachment),
    takeLatest(UserTypes.GET_ATTACHMENTS_SUCCESS, getAttachmentsSuccess),
    takeLatest(UserTypes.GET_ATTACHMENTS, getAttachments),
    takeLatest(UserTypes.GET_POST_CODES, getPostCodes),
    takeLatest(UserTypes.GET_UK_ADDRESS, getUKAddress),
    takeLatest(UserTypes.GET_UK_ADDRESS_SUCCESS, getUKAddressSuccess),
    takeLatest(UserTypes.GET_UK_ADDRESS_FAIL, getUKAddressFail),
    takeLatest(UserTypes.GET_USER, getUser),
    takeLatest(UserTypes.GET_USER_SUCCESS, getUserSuccess),
    takeLatest(UserTypes.GET_ONBOARDING_PROFILES, getOnboardingProfiles),
    takeLatest(UserTypes.GET_ONBOARDING_PROFILES_SUCCESS, getOnboardingProfilesSuccess),
    takeLatest(UserTypes.GET_ROLES, getRoles),
    takeLatest(UserTypes.GET_COUNTRIES, getCountries),
    takeLatest(UserTypes.GET_COUNTRIES_SUCCESS, getCountriesSuccess),
    takeLatest(UserTypes.GET_ROLES_SUCCESS, getRolesSuccess),
    takeLatest(UserTypes.READ_ATTACHMENT, readAttachment),
    takeLatest(UserTypes.REQUEST_CHANGE_USER_PASSWORD, requestChangeUserPassword),
    takeLatest(UserTypes.REQUEST_CHANGE_USER_PASSWORD_SUCCESS, requestChangeUserPasswordSuccess),
    takeLatest(UserTypes.RESET_PASSWORD_FAIL, resetPasswordFail),
    takeLatest(UserTypes.RESET_PASSWORD_SUCCESS, resetPasswordSuccess),
    takeLatest(UserTypes.RESET_PASSWORD, resetPassword),
    takeLatest(UserTypes.SET_CAMPAIGN, setCampaignOnUser),
    takeLatest(UserTypes.SET_CAMPAIGN_SUCCESS, setCampaignSuccess),
    takeLatest(UserTypes.UPDATE_API_MODEL, updateUser),
    takeLatest(UserTypes.UPDATE_USER_AVATAR_FAIL, updateUserAvatarFail),
    takeLatest(UserTypes.UPDATE_USER_AVATAR_SUCCESS, updateUserAvatarSuccess),
    takeLatest(UserTypes.UPDATE_USER_AVATAR, updateUserAvatar),
    takeLatest(UserTypes.UPDATE_USER_FAIL, updateUserFail),
    takeLatest(UserTypes.UPDATE_USER_SUCCESS, updateUserSuccess),
    takeLatest(UserTypes.SEND_FEEDBACK, sendFeedback),
    takeLatest(UserTypes.SEND_FEEDBACK_SUCCESS, sendFeedbackSuccess),
    takeLatest(UserTypes.SEND_FEEDBACK_FAIL, sendFeedbackFail),
    takeLatest(UserTypes.UPLOAD_ATTACHMENTS, uploadAttachments),
    takeLatest(UserTypes.UPLOAD_ATTACHMENTS_SUCCESS, uploadAttachmentsSuccess),
    takeLatest(UserTypes.UPLOAD_ATTACHMENTS_FAIL, uploadAttachmentsFail),
    takeLatest(UserTypes.VALIDATE_ONLINE_BANK_ACCOUNT_USER_SUCCESS, validateOnlineBankAccountUserSuccess),
    takeLatest(UserTypes.VALIDATE_ONLINE_BANK_ACCOUNT_USER, validateOnlineBankAccountUser)
  ];

  const { isDonor } = checkSearchParams(window.location.search);
  yield all([...mainEffects, ...(mode === 'donor' || isDonor ? donorEffects : [])]);
}
