import { put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { I18n } from 'Locales';
import { Result } from 'Repositories';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as TransientTypes } from 'Reducers/transient';
import { ACCEPTED_IMAGE_TYPES, FILE_SIZE_LIMITS } from 'Constants';
import { isOffline as isOfflineSelector } from 'Reducers/application';
import { Types as UserTypes, user as userSelector } from 'Reducers/user';
import { offlineResultsImages as offlineResultsImagesSelector, Types as ResultTypes } from 'Reducers/result';

// EXPORTED
export const get = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ResultTypes.GET_RESULTS_SUCCESS },
      fail: { type: ResultTypes.GET_ERROR }
    },
    promise: Result.get()
  });
};

export const getResultsSuccess = function* ({ payload }) {
  const user = yield select(userSelector);
  const isOffline = yield select(isOfflineSelector);

  yield put({ type: ResultTypes.SET, userId: user.id, payload });

  if (!isOffline) {
    yield put({
      type: ResultTypes.REMOVE_UNSPLICED_OFFLINE_RESULTS,
      userId: user.id
    });
  }
};

export const getError = function* () {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.result.get.title'),
    body: I18n.t('growl:error.result.get.body'),
    kind: 'error'
  });
};

export const performTestResults = function* ({ user }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ResultTypes.PERFORM_TEST_RESULTS_SUCCESS },
      fail: { type: ResultTypes.PERFORM_TEST_RESULTS_FAIL }
    },
    promise: Result.performTestResults(user)
  });
};

export const performTestResultsSuccess = function* ({ payload }) {
  yield put({
    type: UserTypes.UPDATE_PROPS,
    props: {
      test_mode: payload.test_mode
    }
  });
};

export const performTestResultsFail = function* () {
  yield put({
    type: UserTypes.UPDATE_PROPS,
    props: {
      test_mode: false
    }
  });

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.result.testMode.title'),
    kind: 'error'
  });
};

export const resendLinkToDonor = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ResultTypes.RESEND_LINK_TO_DONOR_SUCCESS },
      fail: { type: ResultTypes.RESEND_LINK_TO_DONOR_FAIL }
    },
    promise: Result.resendLinkToDonor(data)
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      resendLinkLoading: true
    }
  });
};

export const resendLinkToDonorSuccess = function* () {
  yield put({ type: TransientTypes.RESET });
};

export const resendLinkToDonorFail = function* ({ error }) {
  yield put({ type: TransientTypes.RESET });
  yield put({
    type: GrowlTypes.ALERT,
    title: error?.response?.data?.message[0].donor_link_error
      ? I18n.t('growl:error.smsServicesFail.title')
      : I18n.t('reports:resendLink'),
    body: error?.response?.data?.errors[0].donor_link_error
      ? I18n.t('growl:error.smsServicesFail.body')
      : I18n.t('reports:resendFail'),
    kind: 'error'
  });
};

export const uploadResultPicture = function* ({ data }) {
  const user = yield select(userSelector);
  const isOffline = yield select(isOfflineSelector);
  const formData = new FormData();
  formData.append('file', data.file);
  const fileType = data.file.type.split('/').pop().toLowerCase();

  if (data.file.size <= FILE_SIZE_LIMITS.ATTACHMENTS) {
    if (!ACCEPTED_IMAGE_TYPES.includes(fileType)) {
      yield put({
        type: GrowlTypes.ALERT,
        title: I18n.t('growl:error.attachmentType.title'),
        body: I18n.t('growl:error.attachmentType.body', { fileType }),
        kind: 'error'
      });
      return;
    }

    let fail = { type: ResultTypes.UPLOAD_RESULT_PICTURE_FAIL };
    if (isOffline) fail = { type: ResultTypes.UPLOAD_OFFLINE_RESULT_PICTURE_SUCCESS, data };

    if (data.id && user.id) {
      const idKey = data.id.indexOf(user.id) !== -1 ? 'offline_id' : 'result_id';
      formData.append(idKey, data.id);
    }

    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: ResultTypes.UPLOAD_RESULT_PICTURE_SUCCESS, data },
        fail
      },
      promise: Result.uploadResultPicture(formData)
    });

    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        pictureUploaded: true
      }
    });
  } else {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.attachmentSize.title'),
      body: I18n.t('growl:error.attachmentSize.body'),
      kind: 'error'
    });
  }
};

export const uploadResultPictureSuccess = function* ({ data }) {
  const user = yield select(userSelector);
  const offlineResultsImages = yield select(offlineResultsImagesSelector);

  const matchingResultMedia = offlineResultsImages?.find(
    item => item?.result_id === data?.id || item?.offline_id === data?.id
  );

  if (matchingResultMedia) {
    yield put({
      type: ResultTypes.DELETE_OFFLINE_PICTURES,
      userId: user.id,
      offlinePictureObject: matchingResultMedia
    });
  }

  yield put({ type: ResultTypes.GET });
};

export const uploadResultPictureFail = function* ({ error }) {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error:generalError.title'),
    body: error?.response?.data?.errors[0],
    kind: 'error'
  });
};

export const uploadOfflineResultPictureSuccess = function* ({ data }) {
  const user = yield select(userSelector);

  yield put({
    type: ResultTypes.ADD_MEDIA_URL_FOR_OFFLINE_RESULT,
    userId: user.id,
    data
  });
};
