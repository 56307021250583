import PropTypes from 'prop-types';
import { Link } from 'framework7-react';
import React, { useState, useEffect } from 'react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import ProfileIcons from 'Icons/Profile';
import { ONBOARDING_FIELDS, PROFILE_FIELDS_DETAILS, TABS, REGIONS } from 'Constants';

import TabIcon from './TabIcon';

const iconMapping = {
  bank_details: ProfileIcons.Bank,
  contact: ProfileIcons.Contact,
  documents: ProfileIcons.Documents,
  identity: ProfileIcons.Identity,
  profile: ProfileIcons.General
};

const ProfileTabSegment = ({
  currentPage = '',
  updateProp = mockFunction,
  user = {},
  viewTypeColumn = true,
  page,
  index
}) => {
  const { isApplicant, onboarding_profile, soft_deactivated } = user;
  const userAcceptedSection = onboarding_profile?.accepted_sections?.by_mc_admin;
  const { bank_details, home_address, emergency, general_details } = userAcceptedSection || {};
  const readOnlyEmergency = soft_deactivated || (emergency && isApplicant);
  const readOnlyHomeAddress = soft_deactivated || (home_address && isApplicant);
  const readOnlyBankDetails = soft_deactivated || (bank_details && isApplicant);
  const readOnlyGeneralDetails = soft_deactivated || (general_details && isApplicant);

  const isUK = REGIONS.UK.includes(user?.country);

  const [gaugePercentage, setGaugePercentage] = useState(0);

  useEffect(() => {
    const newPercentage = calculateGaugePercentage(page, user);
    setGaugePercentage(newPercentage);
  }, [user, currentPage, page]);

  const IconComponent = iconMapping[page];
  const tabColor = page === currentPage ? '#0D8DE7' : '#1C1B1F';

  const isAnyRejectedFieldIncludedInTab = (fieldDetails, tab, rejectedFieldsArray) => {
    if (fieldDetails !== undefined) {
      return rejectedFieldsArray?.some(rejectedField => tab?.includes(rejectedField));
    }
    return tab?.includes(rejectedFieldsArray);
  };

  const totalFieldNo = (fieldsList, fieldDetails, rejectedFieldsArray) => {
    let missingFieldsNo = 0;
    let requiredFieldsNo = 0;

    if (page === TABS.DOCUMENTS) return;

    if (Array.isArray(fieldDetails)) {
      const newFieldsList = [...fieldsList, ...ONBOARDING_FIELDS.EMERGENCY];

      fieldDetails.map(section => {
        const missingFields = newFieldsList.filter(field => user?.field_details[section]?.missing?.includes(field));
        const requiredFields = newFieldsList.filter(field => user?.field_details[section]?.required?.includes(field));

        const rejectedFields = rejectedFieldsArray.filter(rejectedField =>
          user?.onboarding_profile?.rejected_sections[section]?.fields?.includes(rejectedField)
        );

        missingFieldsNo += missingFields.length + rejectedFields.length;
        requiredFieldsNo += requiredFields.length;
      });
    } else {
      const missingFields = fieldsList.filter(field => user?.field_details[fieldDetails]?.missing?.includes(field));
      const requiredFields = fieldsList.filter(field => user?.field_details[fieldDetails]?.required?.includes(field));

      const rejectedFields = rejectedFieldsArray.filter(rejectedField => fieldsList.includes(rejectedField));

      missingFieldsNo = missingFields.length + rejectedFields.length;
      requiredFieldsNo = requiredFields.length;
    }

    return { totalFieldsMissing: missingFieldsNo, totalFieldsRequired: requiredFieldsNo };
  };

  const calculateGaugePercentage = page => {
    let totalRequiredFieldsNo, totalMissingFieldsNo;

    if (page === TABS.DOCUMENTS) {
      const completeDocuments = user?.complete_documents + user?.pending_documents || [];
      const incompleteDocuments = user?.incomplete_documents || [];
      totalRequiredFieldsNo = completeDocuments.length + incompleteDocuments.length;
      totalMissingFieldsNo = incompleteDocuments.length;
    } else {
      const { totalFieldsRequired, totalFieldsMissing } = totalFieldNo(
        ONBOARDING_FIELDS[page.toUpperCase()],
        PROFILE_FIELDS_DETAILS[page.toUpperCase()],
        user?.rejected_fields
      );
      totalRequiredFieldsNo = totalFieldsRequired;
      totalMissingFieldsNo = totalFieldsMissing;
    }

    return totalRequiredFieldsNo !== 0
      ? ((totalRequiredFieldsNo - totalMissingFieldsNo) / totalRequiredFieldsNo) * 100
      : 0;
  };

  const renderGauge = () => {
    /* 
      render gauge if:  
      - user is applicant
      - section is not accepted
      - section has rejected field
    */
    const isAnyRejected = isAnyRejectedFieldIncludedInTab(
      PROFILE_FIELDS_DETAILS[page.toUpperCase()],
      ONBOARDING_FIELDS[page.toUpperCase()],
      user?.rejected_fields
    );

    if (isAnyRejected) return true;

    const isBankDetailsIncomplete = page === TABS.BANK_DETAILS && !bank_details && isApplicant;
    const isProfileOrIdentityIncomplete =
      (page === TABS.PROFILE || page === TABS.IDENTITY) && !general_details && isApplicant;
    const isContactIncomplete =
      page === TABS.CONTACT && (isUK ? !home_address : !emergency || !home_address) && isApplicant;
    const isDocumentsIncomplete = page === TABS.DOCUMENTS && user?.incomplete_documents?.length !== 0;

    return isBankDetailsIncomplete || isProfileOrIdentityIncomplete || isContactIncomplete || isDocumentsIncomplete;
  };

  const renderTabCheckmark = page => {
    if (
      (readOnlyGeneralDetails && (page === TABS.PROFILE || page === TABS.IDENTITY)) ||
      (readOnlyBankDetails && page === TABS.BANK_DETAILS) ||
      ((isUK ? readOnlyHomeAddress : readOnlyEmergency && readOnlyHomeAddress) && page === TABS.CONTACT)
    ) {
      return 'disabled';
    }
    return 'active';
  };

  return (
    <div key={index} width={'auto'} className={'tab-style'} onClick={updateProp.bind(null, 'currentPage', page)}>
      <ColumnView>
        <TabIcon
          icon={IconComponent}
          fillColor={tabColor}
          percentage={gaugePercentage}
          hasGauge={renderGauge()}
          checkMarkStatus={renderTabCheckmark(page)}
        />
        {viewTypeColumn ? (
          <Link tabLink={currentPage} tabLinkActive={page === currentPage}>
            <span className={`tab-label ${page === currentPage && 'active'}`}>{I18n.t(`profile:tabs.${page}`)}</span>
          </Link>
        ) : null}
      </ColumnView>
    </div>
  );
};

ProfileTabSegment.propTypes = {
  currentPage: PropTypes.string,
  index: PropTypes.number,
  page: PropTypes.string,
  viewTypeColumn: PropTypes.bool,
  updateProp: PropTypes.func,
  user: PropTypes.object
};

export default ProfileTabSegment;
