import { createActions, createReducer } from 'reduxsauce';

import { setInitial, updateProp } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    checkIn: null,
    checkOut: ['id'],
    checkOutSuccess: ['userId', 'checkInId'],
    checkOutError: ['error'],
    get: null,
    verifyCheckIn: ['flowId'],
    verifyCheckInSuccess: ['payload', 'flowId'],
    getAddress: ['payload', 'userId'],
    set: ['payload', 'userId'],
    setAddress: ['payload', 'userId', 'checkInId'],
    updateProp: ['key', 'value']
  },
  { prefix: 'checkIn/' }
);

const initialState = {};

const initialCheckInsState = {
  checkIns: []
};

export const set = (state, { payload, userId }) => {
  const newState = setInitial(state, userId, initialCheckInsState);
  newState[userId]['checkIns'] = payload;
  return newState;
};

export const checkOutSuccess = (state, { userId, checkInId }) => {
  const newState = { ...state };

  if (newState[userId]) {
    newState[userId].checkIns = newState[userId]?.checkIns?.filter(checkIn => checkIn.id !== checkInId);
  }

  return newState;
};

export const setAddress = (state, { payload, userId, checkInId }) => {
  let checkIndex;
  for (const [index, c] of state[userId].checkIns.entries()) {
    if (c.id === checkInId) {
      checkIndex = index;
    }
  }

  state[userId]['checkIns'][checkIndex].address = payload;

  return { ...state };
};

export const checkedInPostCodes = state => state.checkIn[state.user.id]?.checkIns[0]?.selected_postcodes;

export default createReducer(initialState, {
  [Types.UPDATE_PROP]: updateProp,
  [Types.SET]: set,
  [Types.CHECK_OUT_SUCCESS]: checkOutSuccess,
  [Types.SET_ADDRESS]: setAddress
});
